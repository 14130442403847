<template>
    <div>
    <v-text-field readonly @click="openFilterModal" label="Filter" append-icon="mdi-filter" value="Filter"
            color="red lighten-1" class="w-full rounded-lg sm:w-28" dense solo hide-details></v-text-field>
    <v-dialog v-model="showDialog" max-width="400px">

        <v-card>
            <v-card-title>
                <span class="text-h5">Filter</span>
            </v-card-title>
            <v-card-text class="flex flex-col">
                <v-select v-if="requiredFilters.banks" :items="banks" item-text="bank" v-model="selectedBank"
                    class="w-full rounded-lg my-4" label="Bank" return-object solo dense hide-details></v-select>
                <v-select v-if="requiredFilters.accounts" :items="accounts" item-text="name" item-value="accountId"
                    v-model="accountId" class="w-full rounded-lg my-4" clearable label="Account" dense solo
                    hide-details></v-select>
                <v-select v-if="requiredFilters.categories" :items="categories" label="Category"
                    class="w-full rounded-lg my-4" v-model="category" clearable dense solo hide-details>
                </v-select>
                <v-dialog v-if="requiredFilters.transactionDate" ref="dialog" width="290px" color="red lighten-1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="transactionDate" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                            color="red lighten-1"
                            class="my-4 rounded-lg v-input v-input--hide-details  v-input--dense theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed  primary--text"></v-text-field>
                    </template>
                    
                    <v-date-picker v-if="requiredFilters.rangePicker" scrollable color="red lighten-1" no-title v-model="transactionDate" range>
                    </v-date-picker>

                    <v-date-picker v-else scrollable color="red lighten-1" no-title v-model="transactionDate" :max="requiredFilters.maxDate" type="month">
                    </v-date-picker>
                </v-dialog>

                <v-card-actions v-if="requiredFilters.searchButton" class="flex justify-center">
                <v-btn :loading="loading" color="red lighten-1" class="w-48 rounded-3xl" dark @click="search">
                    Search
                </v-btn>
            </v-card-actions>
            </v-card-text>

        </v-card>
    </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        banks: {
            type: Array,
            required: false
        },
        accounts: {
            type: Array,
            required: false
        },
        categories: {
            type: Array,
            required: false
        },
        calendarDate: {
            type: String,
            required: false
        },
        requiredFilters: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        // selectedBank: this.banks.length > 0 ? this.banks[0] : null,
        // accountId: null,
        category: null,
        transactionDate: null,
        loading: false,
        showDialog: false,
    }),

    mounted() {
        if (this.calendarDate) {
            this.transactionDate = this.calendarDate;
        }
    },

    computed: {
        selectedBank: {
            get() {
                if (this.banks && this.banks.length > 0) {
                    return this.banks[0];
                }
                return null;
            },
            set(value) {
                this.$emit('bankChangedCallback', value);
            },
        },
        accountId: {
            get() {
                if (this.accounts && this.accounts.length > 0) {
                    return this.accounts[0].accountId;
                }
                return null;
            },
            set(value) {
                this.$emit('accountChangedCallback', value);
            },
        },
    },

    watch: {
        // accountId: function (val) {
        //     this.$emit('accountChangedCallback', val);
        // },
        category: function (val) {
            this.$emit('categoryChangedCallback', val);
        },
        transactionDate: function (val) {
            this.$emit('transactionDateChangedCallback', val);
        }
    },

    methods: {
        search() {
            this.loading = true;
            this.$emit('searchCallback', { bank: this.selectedBank, accountId: this.accountId, category: this.category, transactionDate: this.transactionDate });
            this.loading = false;
            this.showDialog = false;
        },
        openFilterModal() {
            this.showDialog = true;
        }
    }

}
</script>

<style></style>